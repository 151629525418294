<template>
  <UContainer class="py-0 max-w-lg min-h-full flex flex-col" style="min-height: 100vh">
    <NuxtLoadingIndicator />
    <div class="user py-2 px-4 sm:px-6" v-if="showHeader">
      <div class="flex justify-between">
        <a href="/account" class="flex items-center gap-2">
          <UAvatar size="md" :src="user.photoURL" :alt="user.displayName || user.email" />
          <div class="flex flex-col">
            <span class="text-xs">Good day,</span>
            <span class="text-base font-bold">{{ user.displayName || user.email }}</span>
          </div>
        </a>
        <a href="/">
          <img src="/torch.svg" class="h-10 mx-auto" alt="Torch Logo" />
        </a>
      </div>
    </div>
    <slot />
    <UIcon name="i-heroicons-x-mark-20-solid" class="hidden"></UIcon>
    <UNotifications />
    <footer class="footer sticky bottom-0 mt-auto bg-white" v-if="showFooter">
      <div class="flex justify-around">
        <a v-if="['Super Admin', 'Admin'].includes(userData.role)" href="/" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/') }">
          <UIcon name="i-ph-house-simple-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Home</label>
        </a>
        <a v-if="!['Assessor'].includes(userData.role)" href="/entities" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/entities') }">
          <UIcon name="i-ph-gps-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Entities</label>
        </a>
        <a v-if="!['Assessor', 'Entity Admin'].includes(userData.role)" href="/categories" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/categories') }">
          <UIcon name="i-ph-folder-open-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Categories</label>
        </a>
        <a v-if="!['Assessor', 'Entity Admin'].includes(userData.role)" href="/users" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/users') }">
          <UIcon name="i-ph-users-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Users</label>
        </a>
        <a v-if="['Assessor'].includes(userData.role)" href="/tasks" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/tasks') }">
          <UIcon name="i-ph-list-checks-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Tasks</label>
        </a>
        <a href="/account" class="flex flex-col items-center justify-center h-26 w-26 py-6 p-1 hover:text-red-600" :class="{ 'text-red-500': isActivePage('/account') }">
          <UAvatar v-if="user?.photoURL?.length > 0" size="md" :src="user.photoURL" :alt="user.displayName || user.email" />
          <UIcon v-else name="i-ph-user-circle-bold" class="h-10 w-10" />
          <label class="text-sm mt-5">Account</label>
        </a>
      </div>
    </footer>
  </UContainer>
</template>

<script setup>
  const router = useRouter();
  const route = useRoute();
  const user = await useCurrentUser();
 
  const userData = computed(() => {
    if(!user?.value) return null;
    return useState(`userData-${user.value.uid}`).value;
  });

  const showFooter = computed(() => {
    if (!user.value) return false;
    if (!userData.value) return false;
    if (!["/users", "/entities", "/categories", "/account", "/tasks"].includes(route.path)) return false;
    return true;
  });

  const showHeader = computed(() => {
    if (!user.value) return false;
    if (!["/dashboard", "/tasks"].includes(route.path)) return false;
    return true;
  });

  const isActivePage = (page) => {
    return page.includes(route.path);
  };

  // we don't need this watcher on server
  onMounted(() => {
    watch(user, async (user, prevUser) => {
      if (prevUser && !user) {
        // user logged out
        navigateTo("/login");
      } else if (user && typeof route.query.redirect === "string") {
        // user logged in
        router.push(route.query.redirect);
      }
    });
  });
</script>
